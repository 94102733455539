import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import './Login.css';
import {useNavigate} from 'react-router-dom';
import logo from '../../img/dabi-logo.png';
import welcomeimg from '../../img/dabi-movil.png';

function Login(props) {

  const {callbackLogin} = props;

  //* States para login de usuario
  const [emailLog, setEmailLog] = useState('');
  const [contraLog, setContraLog] = useState('');
  const [loginStatus, setLoginStatus] = useState([]);

  Axios.defaults.withCredentials = true;

  const navigate = useNavigate();

  //* Metodo para mandar comparar los datos de login con lo que hay en la base de datos por medio de la API.
  //* La informacion de regreso contiene los datos del usuario en caso de que se encuentre
   const loginUser = () => {
    Axios.post('https://rguapi.appdabi2.com/login', {
      email: emailLog,
      password: contraLog
    }).then((response) => {
      //* Si en la respuesta existe la propiedad message, quiere decir que no encontro un usuario
      if(response.data.message) {
        setLoginStatus([response.data.message]);
      } else {
        callbackLogin([response.data.loggedIn, response.data.user[0]]);
        navigate('/historicoMovil');
      }
    });
  }

  //* Cuando refrescamos la pagina, queremos obtener informacion si hay una sesion
  //* ESTO LO PUEDO UTILIZAR PARA MANDAR LLAMAR UNA TABLA
  useEffect(() => {
    Axios.get('https://rguapi.appdabi2.com/login').then((response) => {
      if(response.data.loggedIn === true) {
        callbackLogin([response.data.loggedIn, response.data.user[0]]);
        navigate('/historicoMovil');
      } else {
        callbackLogin([response.data.loggedIn, {notLoggedIn: 'No ha iniciado sesion'}]);
      }
    });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
  }

  return (
    <div className='main-login'>
      <div className='login-container'>
        <div className='left-side'>
          <div className='img-class'>
            <img src={logo} id='img-id' alt="" />
          </div>
          <p style={{color:"red"}}>{loginStatus[0]}</p>
          <form onSubmit={handleSubmit}>
            <label htmlFor='email'>Correo</label>
            <input type='email' name='email' placeholder='Ingresa tu correo' id='inputEmail' onChange={(e) => setEmailLog(e.target.value)} />
            <label htmlFor='password'>Contraseña</label>
            <input type='password' name='password' placeholder='Ingresa tu contraseña' id='inputPass' onChange={(e) => setContraLog(e.target.value)}/>
            <button type='submit' id='login_button' onClick={loginUser}>Iniciar Sesión</button>
          </form>

          <div className='footer'>
            
          </div>
        </div>        

        <div className='right-side'>
          <div className='welcomeNote'>
            <h3>Bienvenido!</h3>
          </div>
          <div className='welcomeImg'>
            <img src={welcomeimg} id='welcomeimg-id' alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;