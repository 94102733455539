import React, {useEffect, useState} from 'react';
import Axios from 'axios';
import { useTheme } from '@mui/material/styles';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer, Tooltip, CartesianGrid } from 'recharts';
import Titulos from '../Main/Titulos';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';

//Import form filter
import FormControl from '@mui/material/FormControl';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import './HistoricoPortal.css';
import Navbar from '../Navbar/Navbar';
import Footer from '../Navbar/Footer';
import { Button, InputLabel, MenuItem, Select, TextField } from '@mui/material';

const mdTheme = createTheme();

function DashboardContent(props) {
  const theme = useTheme();
  // State para grafica de lineas
  const [totalFechas, setTotalFechas] = useState([]);
  // State para CARD de TOTALES
  const [total, setTotal] = useState('');
  // State para GRAFICA DE BARRAS
  const [totalProyecto, setTotalProyecto] = useState([]);
  // State para TABLA
  const [portalData, setPortalData] = useState([]);
  //State Filtros
  const [fechaInicial, setFechaInicial] = useState({});
  const [fechaFinal, setFechaFinal] = useState({});
  const [proyecto, setProyecto] = useState('');

  const [listaProyectos, setListaProyectos] = useState([]);

  Axios.defaults.withCredentials = true;

  // State para paginacion de tabla
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeProyecto = (event) => {
    setProyecto(event.target.value);
  };

  const preventDefault = (event) => {
    event.preventDefault();
  }

  const handleSubmit = (event) => {
    event.preventDefault();
  }

  //Const permisos style theme tables
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  // Funcion para mostar la lista de proyectos en el select del filtro
  function displayProyectos() {
    return listaProyectos.map((p)=>{
      return <MenuItem value={p.proyecto} key={p.key}>{p.proyecto}</MenuItem>
    });
  }

  function isEmptyObject(value) {
    return value === null || (Object.keys(value).length === 0 && value.constructor === Object);
  }

  //Funcion para reiniciar los filtros
  const restartFilters = ()=>{

    setFechaInicial(null);
    setFechaFinal(null);
    setProyecto('');
    setPage(0);

    //API CALLS para reiniciar los valores iniciales de las graficas despues del filtrado
    // API CALL para GRAFICA DE LINEAS
    Axios.get('https://rguapi.appdabi2.com/historico/datosPortal/totalesFecha').then((response)=>{
      if(response.data) {
        setTotalFechas(response.data);
      }
    });
    // API CALL para CARD DE TOTAL
    Axios.get('https://rguapi.appdabi2.com/historico/datosPortal/totales').then((response)=>{
      if(response.data) {
        setTotal(response.data[0].total);
      }
    });
    // API CALL para GRAFICA DE BARRAS
    Axios.get('https://rguapi.appdabi2.com/historico/datosPortal/totalesProyecto').then((response)=>{
      if(response.data) {
        setTotalProyecto(response.data);
      }
    });
    // API para traer historico de portal sin filtrar
    Axios.get('https://rguapi.appdabi2.com/historico/datosPortal/').then((response)=>{
      // Validamos si la respuesta NO contiene un notFound
      if(!response.data.notFound) {
        setPortalData(response.data);
      }
    });
  }

  // funcion para APIs de filtrado por proyecto
  const filtradoProyecto = () => {
    // API para datos CARD
    Axios.get(`https://rguapi.appdabi2.com/historico/datosPortal/totales/${proyecto}`).then((response)=>{
      if(response.data) {
        setTotal(response.data[0].total);
      }
    });
    // API para datos Tabla
    Axios.get(`https://rguapi.appdabi2.com/historico/datosPortal/${proyecto}`).then((response)=>{
      // Validamos si la respuesta NO contiene un notFound
      if(!response.data.notFound) {
        setPortalData(response.data);
      }
    });
    // API para datos Grafica lineal
    Axios.get(`https://rguapi.appdabi2.com/historico/datosPortal/totalesFecha/${proyecto}`).then((response)=>{
      // Validamos si la respuesta NO contiene un notFound
      if(!response.data.notFound) {
        setTotalFechas(response.data);
      }
    });
    // API para datos grafica barras
    Axios.get(`https://rguapi.appdabi2.com/historico/datosPortal/totalesProyecto/${proyecto}`).then((response)=>{
      if(response.data) {
        setTotalProyecto(response.data);
      }
    });
  }

  // funcion para APIs de filtrado por fechas
  const filtradoFechas = () => {
    // API para datos CARD
    Axios.get(`https://rguapi.appdabi2.com/historico/datosPortal/totales/${fechaInicial}/${fechaFinal}`).then((response)=>{
      if(response.data) {
        setTotal(response.data[0].total);
      }
    });
    // API para datos Tabla
    Axios.get(`https://rguapi.appdabi2.com/historico/datosPortal/${fechaInicial}/${fechaFinal}`).then((response)=>{
      // Validamos si la respuesta NO contiene un notFound
      if(!response.data.notFound) {
        setPortalData(response.data);
      }
    });
    // API para datos Grafica lineal
    Axios.get(`https://rguapi.appdabi2.com/historico/datosPortal/totalesFecha/${fechaInicial}/${fechaFinal}`).then((response)=>{
      // Validamos si la respuesta NO contiene un notFound
      if(!response.data.notFound) {
        setTotalFechas(response.data);
      }
    });
    // API para datos grafica barras
    Axios.get(`https://rguapi.appdabi2.com/historico/datosPortal/totalesProyecto/${fechaInicial}/${fechaFinal}`).then((response)=>{
      if(response.data) {
        setTotalProyecto(response.data);
      }
    });
  }

  const filtradoTodos = () => {
    // API para datos CARD
    Axios.get(`https://rguapi.appdabi2.com/historico/datosPortal/totales/${fechaInicial}/${fechaFinal}/${proyecto}`).then((response)=>{
      if(response.data) {
        setTotal(response.data[0].total);
      }
    });
    // API para datos Tabla
    Axios.get(`https://rguapi.appdabi2.com/historico/datosPortal/${fechaInicial}/${fechaFinal}/${proyecto}`).then((response)=>{
      // Validamos si la respuesta NO contiene un notFound
      if(!response.data.notFound) {
        setPortalData(response.data);
      }
    });
    // API para datos Grafica lineal
    Axios.get(`https://rguapi.appdabi2.com/historico/datosPortal/totalesFecha/${fechaInicial}/${fechaFinal}/${proyecto}`).then((response)=>{
      // Validamos si la respuesta NO contiene un notFound
      if(!response.data.notFound) {
        setTotalFechas(response.data);
      }
    });
    // API para datos grafica barras
    Axios.get(`https://rguapi.appdabi2.com/historico/datosPortal/totalesProyecto/${fechaInicial}/${fechaFinal}/${proyecto}`).then((response)=>{
      if(response.data) {
        setTotalProyecto(response.data);
      }
    });
  }

  // Funcion del boton de filtrado
  const submitFilter = () => {

    // Si estan vacios
    if(isEmptyObject(fechaInicial) && isEmptyObject(fechaFinal) && (proyecto === '' || proyecto === '0')) {
      return;

      // Si solo filtran por fechas
    } else if(!isEmptyObject(fechaInicial) && !isEmptyObject(fechaFinal) && (proyecto === '' || proyecto === '0')) {
      filtradoFechas();
      setPage(0);
      return;

      // Si se filtra por proyecto
    } else if(isEmptyObject(fechaInicial) && isEmptyObject(fechaFinal) && (proyecto !== '' || !proyecto !== '0')) {
      filtradoProyecto();
      setPage(0);
      return;

      // Si se filtra por todo
    } else if(!isEmptyObject(fechaInicial) && !isEmptyObject(fechaFinal) && (proyecto !== '' || !proyecto !== '0')){
      filtradoTodos();
      setPage(0);
      return;

    } else {
      return;
    }
  }

  useEffect(()=>{
    restartFilters();
    // ----------------API CALLS FILTRADO
    Axios.get('https://rguapi.appdabi2.com/historico/datosPortal/listaProyectos').then((response)=>{
      setListaProyectos(response.data);
    });
  }, []);

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Navbar user={props.user}/>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
              <div className="titulos-filtro">
                  <h1>Histórico Portal</h1>
                </div>
                <Paper 
                    sx={{
                      p: 4,
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                    className="filtro-datos"
                >
                  <React.Fragment>
                    <Titulos>Filtrado</Titulos>
                    <ResponsiveContainer>
                      <Box noValidate autoComplete="off">
                        <form onSubmit={handleSubmit}>
                          <FormControl sx={{ height: 'auto' }}>
                          <Grid container spacing={0}>
                            <Grid item xs={12} md={3} lg={3} className='inputs-filtro'>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" className="input-f">Proyecto</InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={proyecto}
                                  label="Proyecto"
                                  onChange={handleChangeProyecto}
                                >
                                  <MenuItem value={'0'}>Seleccionar...</MenuItem>
                                  {displayProyectos()}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3} lg={3} className='inputs-filtro'>
                              <LocalizationProvider dateAdapter={AdapterDayjs} className="input-f">
                                <DatePicker
                                label="Fecha Inicial"
                                value={fechaInicial}
                                onChange={(newValue) => {
                                  try {
                                    // Formatear la fecha que vamos a enviar a la BD
                                    setFechaInicial(newValue.format("YYYY-MM-DD"));                                  
                                  } catch (error) {
                                    setFechaInicial(null);
                                  }
                                }}
                                renderInput={(params) => <TextField {...params} />}
                                inputFormat='YYYY-MM-DD'
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} md={3} lg={3} className='inputs-filtro'>
                              <LocalizationProvider dateAdapter={AdapterDayjs} className="input-f">
                                <DatePicker 
                                label="Fecha Final"
                                value={fechaFinal}
                                onChange={(newValue) => {
                                  try {
                                    // Formatear la fecha que vamos a enviar a la BD
                                    setFechaFinal(newValue.format("YYYY-MM-DD"));                                  
                                  } catch (error) {
                                    setFechaFinal(null);
                                  }
                                }}
                                renderInput={(params) => <TextField {...params} />}
                                inputFormat='YYYY-MM-DD'
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} md={3} lg={3} className='inputs-filtro'>
                              <table>
                                <tbody>
                                  <tr>
                                    <td><Button type="submit" onClick={submitFilter} className='btn-filtrar'>Filtrar</Button></td>
                                    <td><Button type="submit" onClick={restartFilters} className='btn-filtrar'>Reiniciar</Button></td>
                                  </tr>
                                </tbody>
                              </table>
                            </Grid>
                          </Grid>
                          </FormControl>
                        </form>
                      </Box>
                    </ResponsiveContainer>
                  </React.Fragment>
                </Paper>
              </Grid>
              {/*-----------------------------GRAFICA DE LINEAS----------------------------------- */}
              <Grid item xs={12} md={8} lg={9}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  <React.Fragment>
                    <Titulos>Registros por Día</Titulos>
                    <ResponsiveContainer>
                      <LineChart
                        data={totalFechas}
                        margin={{
                          top: 16,
                          right: 16,
                          bottom: 0,
                          left: 24,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="fecha_creacion"
                          stroke={theme.palette.text.secondary}
                          style={theme.typography.body2}
                        />
                        <YAxis
                          stroke={theme.palette.text.secondary}
                          style={theme.typography.body2}
                        >
                          <Label
                            angle={270}
                            position="left"
                            style={{
                              textAnchor: 'middle',
                              fill: theme.palette.text.primary,
                              ...theme.typography.body1,
                            }}
                          >
                            Total
                          </Label>
                        </YAxis>
                        <Tooltip />
                        <Line
                          isAnimationActive={false}
                          type="monotone"
                          dataKey="total"
                          stroke={theme.palette.primary.main}
                          dot={false}
                          connectNulls
                          // activeDot={{onClick: (event, payload)=> {
                          //   setFechaInicial(payload.payload.fecha_creacion);
                          //   setFechaFinal(payload.payload.fecha_creacion);
                          //   // setTotal(payload.payload.total);
                          //   submitFilter();
                          // }}}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </React.Fragment>
                </Paper>
              </Grid>
              {/*-----------------------------FINAL GRAFICA DE LINEAS----------------------------------- */}
              {/* ---------------------------------------CARD DE TOTALES-------------------------------------- */}
              <Grid item xs={12} md={4} lg={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  <React.Fragment>
                    <Titulos>Histórico RGU</Titulos>
                    <Typography component="p" variant="h4">
                      {total}
                    </Typography>
                    <Typography color="text.secondary" sx={{ flex: 1 }}>
                    Año actual: {new Date().getFullYear()}
                    </Typography>
                    <div>
                      <Link color="primary" className="btn-reportes" href="#" onClick={preventDefault}>
                        Ver Reportes
                      </Link>
                    </div>
                  </React.Fragment>
                  {/* ---------------------------------------FINAL CARD DE TOTALES-------------------------------------- */}
                </Paper>
              </Grid>
              {/*----------------------------------------------- GRAFICA BARRAS -------------------------------------------*/}
              <Grid item xs={12} md={12} lg={12}>
                <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 240,
                    }}
                >
                  <React.Fragment>
                    <Titulos>Registros por Proyecto</Titulos>
                    <ResponsiveContainer>
                      <BarChart
                        data={totalProyecto}
                        margin={{
                          top: 16,
                          right: 16,
                          bottom: 0,
                          left: 24,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis
                          dataKey="proyecto"
                          stroke={theme.palette.text.secondary}
                          style={theme.typography.body2}
                        />
                        <YAxis
                          stroke={theme.palette.text.secondary}
                          style={theme.typography.body2}
                        >
                          <Label
                            angle={270}
                            position="left"
                            style={{
                              textAnchor: 'middle',
                              fill: theme.palette.text.primary,
                              ...theme.typography.body1,
                            }}
                          >
                            Total
                          </Label>
                        </YAxis>
                        <Tooltip />
                        <Bar
                          isAnimationActive={false}
                          type="monotone"
                          dataKey="total"
                          label={{ position: 'top' }}
                          fill={theme.palette.primary.main} />
                      </BarChart>
                    </ResponsiveContainer>
                  </React.Fragment>
                </Paper>
              </Grid>
              {/*----------------------------------------------- FINAL GRAFICA BARRAS -------------------------------------------*/}

              {/* ---------------------------------------TABLA MOVIL-------------------------------------- */}
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <React.Fragment>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ maxHeight: 440 }} component={Paper}>
                      <Table stickyHeader sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>ID</StyledTableCell>
                            <StyledTableCell>Usuario</StyledTableCell>
                            <StyledTableCell>Fecha de Creación</StyledTableCell>
                            <StyledTableCell>Proyecto</StyledTableCell>
                            <StyledTableCell>Tipo</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {/* Iteramos cada elemento del array para renderizarlo */}
                          {portalData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => (
                            <TableRow key={row.id} component="tr" scope="row">
                              <StyledTableCell>{row.id}</StyledTableCell>
                              <StyledTableCell>{row.username}</StyledTableCell>
                              <StyledTableCell>{row.fecha_creacion}</StyledTableCell>
                              <StyledTableCell>{row.proyecto}</StyledTableCell>
                              <StyledTableCell>{row.tipo}</StyledTableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={portalData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    </Paper>
                  </React.Fragment>
                  {/* ---------------------------------------FINAL TABLA MOVIL-------------------------------------- */}
                </Paper>
              </Grid>
            </Grid>
            {/* FOOTER */}
            <Footer sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function HistoricoPortal(props) {
  return <DashboardContent user={props.user}/>;
}