import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import PersonIcon from '@mui/icons-material/Person';
import {SettingsCellOutlined} from '@mui/icons-material';
import {Link} from 'react-router-dom';

function MainListItems(props) {
  return (
    <div>
        <Link to='/historicoMovil'>
            <ListItemButton  component="div">
            <ListItemIcon>
                <SettingsCellOutlined />
            </ListItemIcon>
            <ListItemText primary="Histórico Device" />
            </ListItemButton>
        </Link>

        <Link to='/historicoPortal'>
            <ListItemButton  component="div">
            <ListItemIcon>
                <ScreenSearchDesktopIcon />
            </ListItemIcon>
            <ListItemText primary="Histórico Portal"/>
            </ListItemButton>
        </Link>

        {props.role === 1 ?
        <Link to='/agregarUsuario'>
            <ListItemButton  component="div">
            <ListItemIcon>
                <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="Usuarios"/>
            </ListItemButton>
        </Link>
        : null}
    </div>
  )
}

export default MainListItems