import React, { useEffect } from 'react';
import { StyledEngineProvider } from '@mui/material/styles';
import Axios from 'axios';
import './App.css';

// Components
import Main from './components/Main/Main';

function App() {

  Axios.defaults.withCredentials = true;

  useEffect(() => {
    const handleTabClose = event => {
      event.preventDefault();

      Axios.get('https://rguapi.appdabi2.com/logout').then((response) => {
          if(response.data.success) {
          console.log(response.data.success);
          } else {
          console.log(response.data.error);
          }
      });

      console.log('beforeunload event triggered');
      
      return (event.returnValue = 'Are you sure you want to exit?');
    };

    window.addEventListener('beforeunload', handleTabClose);

    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, []);

  return (
    <div className="App">
      <header className='App-header'>
        <StyledEngineProvider injectFirst>
          <Main />
        </StyledEngineProvider>
      </header>
    </div>
  );
}

export default App;