import React, { useState } from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

// Components
import Login from '../Login/Login';
import Register from '../Register/Register';
import HistoricoMovil from '../HistoricoMovil/HistoricoMovil';
import NotFound from '../NotFound/NotFound';
// import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';
// import PublicRoutes from '../PublicRoutes/PublicRoutes';
import HistoricoPortal from '../HistoricoPortal/HistoricoPortal';

function Main() {

  const [login, setLogin] = useState([false, {}]);

  const getLogIn = (data) => {
    setLogin(data);
  }

  return (
    <div>
        <Router>          
          <Routes>
            {/* Protected Routes */}
             <Route path='/historicoPortal' element={ !login[0] ? <Login callbackLogin={getLogIn}/> : <HistoricoPortal user={login[1]}/> }/>
              <Route path='/historicoMovil' element={ !login[0] ? <Login callbackLogin={getLogIn}/> : <HistoricoMovil user={login[1]}/> }/>
              <Route path='/agregarUsuario' element={ !login[0] ? <Login callbackLogin={getLogIn}/> : <Register user={login[1]}/> }/>

            {/* Public Routes */}
              <Route path='/' element={ <Login callbackLogin={getLogIn}/> }/>
            <Route path='*' element={<NotFound/>} />
          </Routes>

        </Router>
    </div>
  )
}

export default Main;