import React, {useState, useEffect} from 'react';
import './Register.css';
import {Link} from 'react-router-dom';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';

function Register(props) {
  // states para cambios en los inputs
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [status, setStatus] = useState(0);
  const [role, setRole] = useState(0);
  const [apiCall, setApiCall] = useState('');

  // states para obtener los datos para formulario
  const [apiStatus, setApiStatus] = useState([]);
  const [apiRole, setApiRole] = useState([]);

  const navigate = useNavigate();

  Axios.defaults.withCredentials = true;

  // Funciones para mostrar las opciones de los dropdown menus
  function displayStatus() {
    return apiStatus.map((st) => {
      return <option value={st.id_status} key={st.id_status}>{st.status}</option>;
    });
  }
  // Funciones para mostrar las opciones de los dropdown menus
  function displayRoles() {
    return apiRole.map((r) => {
      return <option value={r.id_role} key={r.id_role}>{r.role}</option>
    });
  }
  
  // Funcion para mandar los datos del nuevo usuario
  const addUser = () => {
    // Validaciones del formulario
    // Prueba
    if(firstName === '' || lastName === '' || email === '' || pass === '' || role === 0 || status === 0) {
      setApiCall('Faltan datos');
      return;
    } 
    Axios.post('https://rguapi.appdabi2.com/usuarios/agregarUsuario', {
      firstName,
      lastName,
      email,
      pass,
      status,
      role,
      created_by: props.user.firstName + " " + props.user.lastName,
      modified_by: props.user.firstName + " " + props.user.lastName,
    }).then((response) => {
      if(response.data.error) {
        setApiCall(response.data.error);
      } else {
        setApiCall(response.data.message);
        navigate('/historicoMovil');
      }
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
  }


  useEffect(() => {
    // API para obtener listado de estatus
    Axios.get('https://rguapi.appdabi2.com/listaEstatus').then((response) => {
      if(!response.data.message) {
        setApiStatus(response.data);
      }
    });
    // API para obtener listado de roles
    Axios.get('https://rguapi.appdabi2.com/listaRoles').then((response) => {
      if(!response.data.message) {
        setApiRole(response.data);
      }
    });
  }, []);

  return (
    <div className="container">
      <Navbar user={props.user}/>
      <div className='main-register'>
        <div id="feedback-form">
        <div className='right-side'>
            <h4>Registrar Usuario</h4>
            <p>{apiCall}</p>
            <form onSubmit={handleSubmit}>
              <label htmlFor='firstName'>Nombre</label>
              <input type="text" name='firstName' placeholder='Nombre' onChange={(e) => setFirstName(e.target.value)}/>

              <label htmlFor='lastName'>Apellidos</label>
              <input type="text" name='lastName' placeholder='Apellidos' onChange={(e) => setLastName(e.target.value)}/>

              <label htmlFor='email'>Correo</label>
              <input type="email" name='email' placeholder='Correo electronico' onChange={(e) => setEmail(e.target.value)}/>

              <label htmlFor='password'>Contraseña</label>
              <input type="password" name='password' placeholder='Contraseña' onChange={(e) => setPass(e.target.value)}/>

              <label htmlFor='status'>Estatus:</label>
              <select name='status' onChange={(e) => setStatus(parseInt(e.target.value))}>
                <option value={0}>Selecciona un estatus</option>
                {displayStatus()}
              </select>

              <label htmlFor='roles'>Rol:</label>
              <select name='roles' onChange={(e) => setRole(parseInt(e.target.value))}>
                <option value={0}>Selecciona un rol</option>
                {displayRoles()}
              </select>


              <button type='submit'  onClick={addUser}>Añadir</button>
            </form>
            <Link to='/historicoMovil' ><span id="btn-login">Ir a Inicio</span></Link>
          </div>
          </div>
      </div>
    </div>
  );
}

export default Register;